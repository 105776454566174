<template>
  <vs-card>
    <h3 class="mb-4">
      Novo Sorteio
    </h3>

    <div class="d-flex flex-column flex-nowrap align-items-start">
      <form>
        <!-- General -->
        <vs-row>
          <vs-col
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-row class="mb-4">
              <!-- Prize Project -->
              <vs-col
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <label>
                  <strong>Grupo de Sorteio*</strong>
                </label>

                <div class="d-flex align-items-center">
                  <vs-select
                    v-model="selectedPrize"
                    :style="{ width: '260px' }"
                    :warning="readonly"
                    warning-text="Este campo é obrigatório"
                  >
                    <vs-select-item
                      v-for="project in prizeProjects"
                      :key="project.prizeCode"
                      :value="project"
                      :text="project.prizeName"
                    />
                  </vs-select>

                  <div
                    class="d-flex ml-2"
                    :class="!readonly ? 'mb-1' : 'mb-4'"
                  >
                    <vs-tooltip
                      v-if="!readonly"
                      text="Editar Grupo"
                    >
                      <vs-button
                        type="border"
                        icon="edit"
                        size="small"
                        @click="goToEditPrize"
                      />
                    </vs-tooltip>

                    <vs-tooltip text="Criar Novo Grupo">
                      <vs-button
                        class="ml-1"
                        icon="add"
                        size="small"
                        @click="goToCreatePrize"
                      />
                    </vs-tooltip>
                  </div>
                </div>
              </vs-col>
            </vs-row>

            <vs-divider />

            <vs-row>
              <vs-col
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
              >
                <vs-row>
                  <vs-col
                    vs-lg="3"
                    vs-sm="12"
                    vs-xs="12"
                  >
                    <vs-input
                      v-model="prizeDate"
                      v-mask="'##/##/####'"
                      class="w-100"
                      icon="event"
                      label="Data do Sorteio*"
                      placeholder="DD/MM/YYYY"
                      :disabled="readonly"
                    />
                  </vs-col>

                  <vs-col
                    vs-lg="3"
                    vs-sm="12"
                    vs-xs="12"
                  >
                    <vs-input
                      v-model="prizeTime"
                      v-mask="'##:##'"
                      class="w-100"
                      icon="schedule"
                      label="Horário do Sorteio*"
                      placeholder="00:00"
                      :disabled="readonly"
                    />
                  </vs-col>

                  <vs-col
                    vs-lg="3"
                    vs-sm="12"
                    vs-xs="12"
                    class="d-flex flex-column"
                  >
                    <label class="vs-input--label">
                      Número de ganhadores*
                    </label>

                    <div class="d-flex align-items-center mr-5">
                      <vs-button
                        icon="remove"
                        radius
                        size="small"
                        :disabled="readonly"
                        @click="handleQtyRemove"
                      />

                      <vs-input
                        v-model.number="prizeQty"
                        class="c-draw-number-input"
                        type="number"
                        :disabled="readonly"
                      />

                      <vs-button
                        icon="add"
                        class="mr-5"
                        radius
                        size="small"
                        :disabled="readonly"
                        @click="handleQtyAdd"
                      />
                    </div>
                  </vs-col>

                  <vs-col
                    vs-lg="3"
                    vs-sm="12"
                    vs-xs="12"
                    class="d-flex align-items-center"
                  >
                    <PrizeDrawBlacklist
                      :restriction-limit-date="restrictionLimitDate"
                      :restriction-type="restrictionType"
                      @set-restriction-limit-date="(val) => (restrictionLimitDate = val)"
                      @set-restriction-type="(val) => (restrictionType = val)"
                    />
                  </vs-col>
                </vs-row>

                <vs-row class="mt-3">
                  <vs-col>
                    <small>
                      Obs.: A ação de sorteio só será liberada a partir da data e horário informados.
                    </small>
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>

        <vs-divider class="mb-4" />

        <!-- Registration -->
        <vs-card>
          <div
            slot="header"
            class="d-flex align-items-center"
          >
            <vs-checkbox
              v-model="signToParticipate"
              :color="colorSecondary"
              :disabled="readonly"
            >
              <h5>Inscrições</h5>
            </vs-checkbox>
          </div>

          <vs-row>
            <!-- General -->
            <vs-col
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >
              <vs-row>
                <!-- Date/Time Start -->
                <vs-col
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                  class="mb-4"
                >
                  <vs-row>
                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignInitDate"
                        v-mask="'##/##/####'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="today"
                        label="Data de Início"
                        placeholder="DD/MM/YYYY"
                        :warning="!validSignInitDate"
                        warning-text="O início das inscrições deve ser antes do sorteio"
                      />
                    </vs-col>

                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignInitTime"
                        v-mask="'##:##'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="schedule"
                        label="Horário de Início"
                        placeholder="00:00"
                      />
                    </vs-col>
                  </vs-row>
                </vs-col>

                <!-- Date/Time End -->
                <vs-col
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <vs-row>
                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignEndDate"
                        v-mask="'##/##/####'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="event"
                        label="Data Final"
                        placeholder="DD/MM/YYYY"
                      />
                    </vs-col>

                    <vs-col
                      vs-lg="6"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <vs-input
                        v-model="prizeSignEndTime"
                        v-mask="'##:##'"
                        class="w-100"
                        :disabled="!signToParticipate"
                        icon="schedule"
                        label="Horário Final"
                        placeholder="00:00"
                      />
                    </vs-col>
                  </vs-row>
                </vs-col>
              </vs-row>
            </vs-col>

            <vs-col
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              :style="{ paddingLeft: '2rem' }"
            >
              <h6 class="mb-3">
                Pesquisa de Inscrição
              </h6>

              <!-- Survey Params -->
              <PrizeSurveyParamsForm
                :params="surveyParams"
                :sign-to-participate="signToParticipate"
                @set-params="setSurveyParams"
              />
            </vs-col>
          </vs-row>
        </vs-card>
      </form>

      <small class="mt-2 mb-3">
        * Indica campos obrigatórios
      </small>

      <vs-button
        :disabled="!validForm"
        size="large"
        @click="createDraw"
      >
        Criar Sorteio
      </vs-button>
    </div>
  </vs-card>
</template>

<script>
/* Components */
import PrizeDrawBlacklist from '../components/PrizeDrawBlacklist.vue';
import PrizeSurveyParamsForm from '../components/PrizeSurveyParamsForm.vue';

/* Directives */
import { mask } from 'vue-the-mask';

/* Helpers */
import moment from 'moment';

/* Services */
import Prize from '@/services/prize';

/* Validations */
import validatePrizeDraw from '../helpers/validate-prize-draw';

/* Style */
import '../assets/index.scss';

const prizeService = new Prize();

export default {
  name: 'PrizeDrawView',
  components: {
    PrizeDrawBlacklist,
    PrizeSurveyParamsForm,
  },
  directives: { mask },
  data: () => ({
    prizeProjects: [],
    /* General */
    selectedPrize: {},
    prizeDate: '',
    prizeTime: '',
    prizeQty: 0,
    /* Blacklist */
    restrictionLimitDate: '',
    restrictionType: 1,
    /* Survey */
    surveyParams: {},
    prizeSignEndDate: '',
    prizeSignEndTime: '',
    prizeSignInitDate: '',
    prizeSignInitTime: '',
    signToParticipate: false,
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    validSignEndDate() {
      return moment(this.prizeSignEndDate, 'DD/MM/YYYY', true).isValid();
    },
    validSignInitDate() {
      return this.validateDateRange([
        this.prizeSignInitDate,
        this.prizeDate,
      ]);
    },
    /* Form */
    drawPayload() {
      return {
        ownerId: this.ownerId,
        prizeCode: this.selectedPrize.prizeCode,
        prizeDate: this.prizeDate,
        prizeTime: this.prizeTime,
        signToParticipate: this.signToParticipate,
        prizeSignInitDate: this.prizeSignInitDate,
        prizeSignInitTime: this.prizeSignInitTime,
        prizeSignEndDate: this.prizeSignEndDate,
        prizeSignEndTime: this.prizeSignEndTime,
        prizeQty: this.prizeQty,
        surveyParams: this.surveyParams,
        restrictionType: this.restrictionType,
        restrictionLimitDate: this.restrictionLimitDate,
      };
    },
    readonly() {
      return Object.keys(this.selectedPrize).length === 0;
    },
    validForm() {
      return (
        validatePrizeDraw(this.$data)
        && this.validSignEndDate
        && this.validSignInitDate
      );
    },
  },
  watch: {
    selectedPrize(value) {
      if (value.lastWinnersQty) {
        this.prizeQty = value.lastWinnersQty;
      }
    },
  },
  created() {
    this.getPrizeProjects();
  },
  methods: {
    /* API */
    createDraw() {
      prizeService.createPrizeDraw(
        this.drawPayload,
        this.token,
      )
        .then(() => {
          this.successAlert();
          this.goToDraws();
        })
        .catch((err) => this.errorAlert(err));
    },
    getPrizeProjects() {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService.getPrizeProjects(
        this.ownerId,
        this.token,
      )
        .then((resp) => (this.prizeProjects = resp))
        .catch((err) => this.errorAlert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    handleQtyAdd() {
      const max = this.filterIdCount >= 1 ?
        this.filterIdCount :
        500;

      if (this.prizeQty < max) this.prizeQty += 1;
    },
    handleQtyRemove() {
      if (this.prizeQty > 1) this.prizeQty -= 1;
    },
    setSurveyParams(value) {
      this.surveyParams = value;
    },
    validateDateRange(dateRange) {
      const firstDate = moment(dateRange[0], 'DD/MM/YYYY', true);
      const lastDate = moment(dateRange[1], 'DD/MM/YYYY', true);

      if (firstDate.isValid() && lastDate.isValid()) {
        return lastDate.isAfter(firstDate);
      } else {
        return true;
      }
    },
    /* Alerts */
    errorAlert(err) {
      console.error(err);

      this.$vs.notify({
        color: 'danger',
        title: 'Erro',
        text: err,
      });
    },
    successAlert() {
      this.$vs.notify({
        color: 'success',
        title: 'Sucesso',
        text: 'Sorteio salvo com sucesso!',
      });
    },
    /* Router */
    goToCreatePrize() {
      this.$router.push('/sorteios/grupos/criar');
    },
    goToEditPrize() {
      this.$router.push({
        name: 'Editar Grupo de Sorteio',
        params: { selectedPrize: this.selectedPrize },
      });
    },
    goToDraws() {
      this.$router.push('/sorteios/listar');
    },
  },
};
</script>

<style lang="scss">
/* Custom Draw Components */
.c-draw-link {
  color: black !important;
  font-size: 0.95em;
  text-decoration: underline !important;
}

.c-draw-link:hover {
  color: inherit !important;
  cursor: pointer !important;
}

.c-draw-number-input {
  margin: 0 7px;

  .vs-input--input {
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.c-draw-vs-chip {
  display: flex;
  justify-content: stretch;

  .text-chip.vs-chip--text {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: space-around;
    width: 100%;
  }
}
</style>
